@import '~antd/dist/antd.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

body {
  font-family: 'Nanum Gothic', sans-serif;
}

#sider {
  height: calc(100vh - 64px);
}

.hl-body {
  height: calc(100vh - 64px);
  padding: 15px 15px 0px 15px;
}

.hl-content {
  height: calc(100vh - 112px); /* 100vh - main menu(64) - sub header(48) */
  padding: 15px 15px 0px 15px;
}

.hl-content-layout {
  background: #fff;
  padding: 24px;
  margin: 0;
  overflow: scroll;
}

.applogo {
  width: 130px;
  height: 32px;
  margin: 16px 50px 16px 0;
  float: left;
  background: url('/images/hanlim-group.png') no-repeat;
  background-size: contain;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
}

.min-width-70 {
  min-width: 70px;
}
.min-width-100 {
  min-width: 100px;
}
.min-width-200 {
  min-width: 200px;
}

.hl-bg-yellow {
  background: #fdffe7;
}

.hl-bg-blue {
  background: #e6f7ff;
}

.hl-fg-blue {
  color: blue;
}

.hl-fg-black {
  color: black;
}

.hl-bg-red {
  background: #fff1f0;
}

.hl-bg-gray {
  background: #f5f5f5;
}

.hl-vertical-top {
  vertical-align: text-top;
}

tr.hl-border-top td {
  border-top: 1px solid #ccc;
}

.hl-border-left {
  border-left: 1px solid #ccc;
}

.hl-body-small {
  font-size: 11px;
}

/* .hl-hide-overflow {
  overflow: hidden;
  white-space: nowrap;
  width: 200;
} */

.hl-form {
  padding: 7px 9px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.hl-form-inline-text {
  margin-left: 15px;
  margin-right: 5px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  /* text-overflow: ellipsis; */
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.avatar-uploader > .ant-upload {
  width: 130px;
  height: 130px;
}

/* -----------------------------
    image-gallery related
   ----------------------------- */

/* 
.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
 */

.image-gallery {
  width: 100%;
  height: auto;
}
.image-gallery-slide {
  width: 100%;
  height: auto;
  max-height: 80vh;
  overflow: hidden;
  object-position: center center;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
  background-color: yellow;
  object-fit: contain;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

/* 
    Custom animation on slide (image transitions)
    https://github.com/xiaolin/react-image-gallery/issues/166
  */
.image-gallery-slide {
  opacity: 0.6;
  transition: opacity 300ms ease-out !important;
}
.image-gallery-slide.center {
  opacity: 1;
}

.absolute-center-parent {
  width: 100px; /* Set dimensions here */
  height: 100px; /* Set dimensions here */
  background-color: #fff; /* To help show our containing box */
  position: relative;
}

.absolute-center-parent-gallery {
  width: 90px; /* Set dimensions here */
  height: 90px; /* Set dimensions here */
  background-color: #fff; /* To help show our containing box */
  position: relative;
  margin: 0 auto;
}

.absolute-center-child {
  max-width: 100%; /* Fill size of parent */
  max-height: 100%; /* Fill size of parent */
  /* And remaining lines to center */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* -----------------------------
    antd override - fonts
   ----------------------------- */
body,
.ant-btn,
.ant-card,
.ant-cascader-menus,
.ant-cascader-picker,
.ant-comment-content,
.ant-descriptions-item-content,
.ant-descriptions-item-label,
.ant-drawer-body,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-empty,
.ant-form,
.ant-form label,
.ant-form-explain,
.ant-form-extra,
.ant-form-item,
.ant-fullcalendar,
.ant-input-number,
.ant-input,
.ant-layout-footer,
.ant-list,
.ant-list-item-meta-title,
.ant-list-item-meta-description,
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu,
.ant-modal-body,
.ant-pagination .ant-select-dropdown,
.ant-popover,
.ant-progress-text,
.ant-radio-group,
.ant-select-dropdown,
.ant-select-dropdown-menu-item,
.ant-select-tree,
.ant-select,
.ant-steps-small .ant-steps-item-title,
.ant-table,
.ant-table-column-title,
.ant-table-tbody,
.ant-table-thead,
.ant-tabs,
.ant-tabs-nav-container,
.ant-timeline-item,
.ant-tree,
.ant-upload-list-item {
  font-size: 12px;
}
.ant-card-head,
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  font-size: 15px;
}
h2 {
  font-weight: bold;
  font-size: 1.4em;
}
.hl-steps .ant-steps-item-description {
  font-size: 11px;
}

/* -----------------------------
    antd override
   ----------------------------- */
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-layout-header {
  padding: 0 20px;
}
.ant-empty-normal {
  color: #999;
}

.ant-input-number-input {
  text-align: right;
  padding-right: 30px;
}

.hl-no-padding {
  padding: 0;
}

.ant-input-number-disabled,
.ant-select-disabled,
.ant-input[disabled] {
  color: #555;
}

/* remove child antd table border */
.hl-no-border .ant-table-small {
  border: none;
}

.ant-form-item {
  margin-bottom: 1px;
}

.ant-table-middle
  > .ant-table-content
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-middle
  > .ant-table-content
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-middle
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 3px 5px; /* default: 12px 8px; */
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 3px; /* 16px 16px */
}

.only-print {
  display: none;
}
.only-screen {
  display: block;
}

/* -----------------------------
    print overrides 
    only-print, not-print 등
    참고: https://riptutorial.com/ko/css/example/7625/%EB%AF%B8%EB%94%94%EC%96%B4-%EC%A2%85%EB%A5%98
   ----------------------------- */
@media print {
  body {
    padding: 10px;
  }
  .only-print {
    display: block;
  }
  .only-screen {
    display: none;
  }

  .hl-body-small {
    font-size: 10px;
  }

  body,
  .ant-btn,
  .ant-card,
  .ant-cascader-menus,
  .ant-cascader-picker,
  .ant-comment-content,
  .ant-descriptions-item-content,
  .ant-descriptions-item-label,
  .ant-drawer-body,
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  .ant-empty,
  .ant-form,
  .ant-form label,
  .ant-form-explain,
  .ant-form-extra,
  .ant-form-item,
  .ant-fullcalendar,
  .ant-input-number,
  .ant-input,
  .ant-list,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu,
  .ant-modal-body,
  .ant-pagination .ant-select-dropdown,
  .ant-popover,
  .ant-progress-text,
  .ant-radio-group,
  .ant-select-dropdown,
  .ant-select-dropdown-menu-item,
  .ant-select-tree,
  .ant-select,
  .ant-steps-small .ant-steps-item-title,
  .ant-table,
  .ant-table-column-title,
  .ant-table-tbody,
  .ant-table-thead,
  .ant-tabs,
  .ant-tabs-nav-container,
  .ant-timeline-item,
  .ant-tree,
  .ant-upload-list-item {
    font-size: 10px;
  }
  .ant-card-head,
  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    font-size: 13px;
  }
  .hl-steps .ant-steps-item-description {
    font-size: 9px;
  }
}
