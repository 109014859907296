.logo-section {
  margin: 40px 0;
  max-width: 360px;
}

.login-wrapper {
  margin: auto;
  padding: 170px 0;
  text-align: center;
  max-width: 360px;
}

.login-form {
  width: 100%;
}

.login-form-button {
  width: 100%;
}

.error-alerts {
  margin: 15px 0;
}
